
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import Form from 'react-bootstrap/Form'
// import { NavLink } from 'react-router-dom';
// import Button from 'react-bootstrap/Button'
// import FormControl from 'react-bootstrap/FormControl'



const Navigation = (props) => {
  return (
    //   <Navbar bg="primary" variant="dark">
    //   <Form inline style={{ width: '100%', display: 'unset', float: 'right' }}>
    //    <NavLink to="/addProducts"> <Button variant="success" style={{ float: 'left' }}>Adding Stock</Button></NavLink>
    //    <NavLink to="/"><Button variant="secondary" style={{ marginRight: '20px' }}>Stock Check</Button></NavLink>
    //    <NavLink to="/sell"><Button variant="warning" style={{ width: '250px' }}>Selling</Button></NavLink>
    //     <Button variant="outline-info" style={{ float: 'right', display: 'none' }}>Orders</Button>
    //    {props.idAuth? <Button variant="outline-warning" onClick={props.logout} style={{float: 'right', width: '250px' }}>Logout</Button> : <NavLink to="/login"><Button variant="warning" style={{ float: 'right' }}>Login</Button></NavLink>}
    //   </Form>
    // </Navbar>
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">Home</Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/"></Nav.Link>
      </Nav>
      {/* <Form inline>
        <FormControl type="text" placeholder="Search" className="mr-lg-2" />
        <Button variant="outline-info">Search</Button>
      </Form> */}
    </Navbar>
  )

}



export default Navigation;

