import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actions from './index';

export const getProductStart = () => {
    return {
        type: actionTypes.FETCH_SHOP_ITEMS_START,
        products:[]
    };
}



export const getProductSuccess = (products, per_page, page, barcode, search) => {
   // console.log(products)
    return {
        type: actionTypes.FETCH_SHOP_ITEMS_SUCCESS,
        page: page,
        productNumber: per_page,
        error: null,
        loading: false,
        products: products,
        search: search,
        barcode: barcode
    };
}

export const getProductFail = (error) => {
    return {
        type: actionTypes.FETCH_SHOP_ITEMS_FAIL,
        error: error
    };
}

export const cleanup = () => {
    return {
        type: actionTypes.FETCH_PRODUCT_BY_BARCODE_CLEAR,
        products:[]
    };
}

export const getProducts = (key, secret, domain, page, per_page, search, barcode) => {
    return dispatch => {
        dispatch(getProductStart());
        let url = `https://${domain}/wp-json/wc/v3/products?page=${page}&per_page=${per_page}&search=${search}&sku=${barcode}&consumer_key=${key}&consumer_secret=${secret}`;
        //console.log(url);
        axios.get(url)
            .then(response => {
             //   console.log(response);
             dispatch(getProductSuccess(response.data,per_page,page,barcode,search));
            })
            .catch(err => {
                console.log(err);
                actions.setLog("ERROR", err, "auth", `key = ${key}, domain = ${domain}, secret =${secret},search=${search},barcode = ${barcode},page=${page},per_page=${per_page}`, 0, "Inventory System Wordpress", "products.js", domain);
             //   console.log(url);
                dispatch(getProductFail(err.response));
            })
    }
}

