
import './App.css';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/action/index';

import Layout from './pages/Layout';

import Login from './pages/Login/login';
import Sell from './pages/sell/sell';
import Shop from './pages/shop/shop';
import AddProducts from './pages/addProducts/addProduct';
import LogedIn from './pages//logedIn/LogedIn';




function App(props) {

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to='/login' />
    </Switch>
  );
  let mainComponent = <div>{routes}</div>;

  if (props.isAuth) {
    routes = (
      <Switch>
        <Route path="/sell" component={Sell} />
        <Route path="/addProducts" component={AddProducts} />
        <Route path="/shop" exact component={Shop} />
        <Route path="/" component={LogedIn} /> 
        <Redirect to='/' />
      </Switch>
    ); 
    mainComponent = (<Layout>{routes}</Layout>);
  }

  return (
    <div className="App">
      {mainComponent}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.key !== null
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAuthSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));