import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actions from './index';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}



export const authSuccess = (key, secret,domain) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        key: key,
        secret: secret,
        domain: domain,
    };
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
}

export const logout = () => {
    localStorage.removeItem('key'); 
    localStorage.removeItem('secret');
     localStorage.removeItem('domain');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (key, secret, domain) => {
    return dispatch => {
        dispatch(authStart());
         
        let url = 'https://'+domain+'/wp-json/wc/v3/products?page=1&per_page=1&consumer_key='+key+'+&consumer_secret='+secret;
        
        axios.get(url)
            .then(response => {
                console.log(response);
                if(response.data[0].message==null){ 
                localStorage.setItem('key', key);
                localStorage.setItem('domain', domain);
                localStorage.setItem('secret', secret);
                dispatch(authSuccess(key, secret,domain))
                }else{
                    dispatch(authFail(response[0].message));
                }
            })
            .catch(err => {
                console.log(err);
                actions.setLog("ERROR", err, "auth", `key = ${key}, domain = ${domain}, secret =${secret}`, 0, "Inventory System Wordpress", "auth.js", domain);
                dispatch(authFail(err.response.data.error));
            })
    }
}


export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}


export const authCheckState = () => {
    return dispatch => {
        const key = localStorage.getItem('key');
        if (!key) {
            dispatch(logout());
        } else {
            const secret = localStorage.getItem('secret');
            const domain = localStorage.getItem('domain');
            dispatch(authSuccess(key, secret,domain));
        }
    };
};