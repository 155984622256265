import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as actions from './index';

export const updateProductStart = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_START
    };
}

export const updateProductSuccess = (product, idIN) => {
    // console.log(products)
    return {
        type: actionTypes.UPDATE_PRODUCT_SUCCESS,
        id: idIN,
        error: null,
        loading: false,
        product: product
    };
}

export const updateProductFail = (error) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_FAIL,
        error: error
    };
}

export const updateProducts = (key, secret, domain, productId, productUpdate) => {
    return dispatch => {
        dispatch(updateProductStart());
        axios.put(`https://${domain}/wp-json/wc/v3/products/${productId}?consumer_key=${key}&consumer_secret=${secret}`, productUpdate)
            .then(res => {
                console.log(res.data);
                dispatch(updateProductSuccess(res.data, productId));
            })
            .catch(err => {
                console.log(err);
                actions.setLog("ERROR", err, "auth", `key = ${key}, domain = ${domain}, secret =${secret},productId = ${productId}, productToUpdate=${JSON.stringify(productUpdate)}`, 0, "Inventory System Wordpress", "product_update.js", domain);
                dispatch(updateProductFail(err));
            })
    }
}

