import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import ProductListItem from '../../components/UI/ProductListItem/ProductListItem';
import { getCurrentTime } from '../../store/Utilities';
import { connect } from 'react-redux';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import * as actions from '../../store/action/index';


const Sell = (props) => {


    const [barcodeInput, setBarcodeInput] = useState("");
    const [barcodeList, setbarcodeList] = useState([]);
    const [indexBarcode, setIndexBarcodeList] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0.0);
    const [errorShow, setError] = useState("");
    const [acceptsPayment_button, setAcceptsPayment] = useState(false);

    const enterClicked = (e) => {

        if (e.key === 'Enter') {

            let foundIndex = barcodeList.findIndex(x => x.barcode === barcodeInput);
            //console.log(foundIndex);
            if (foundIndex !== -1) {// here we do update  
                let list = barcodeList;
                list[foundIndex].count = list[foundIndex].count + 1;
                setbarcodeList(list);
                updateTotalPrice();
            } else { // here we just add 
                console.log("don't exist");
                setbarcodeList(barcodeList => [...barcodeList, { currentTime: getCurrentTime(), barcode: barcodeInput, index: indexBarcode, count: 1, price: 0, productID: 0,deleted: false}]);
            }
            setIndexBarcodeList(indexBarcode + 1);
            setBarcodeInput("");

            console.log(barcodeList);
        }

    }

    const setDeleted=(position)=>{
        let foundIndex = barcodeList.findIndex(x => x.index === position);
        let list = barcodeList;
        list[foundIndex].deleted = true;
        setbarcodeList(list);
    }

    const removeObjectFromListAtPosition = (position) => {
        console.log("Remove from position in List parent" + position);
        let list = barcodeList;
        const removeItem = (items, i) => items.filter(item => item.index !== position && item.deleted===false);
        let  listUpdated= removeItem(list, position); 
        console.log("Removed !!!")
        let totalPrice = 0;
        listUpdated.forEach(element => totalPrice += (element.price * element.count));
        console.log(barcodeList);
        console.log(totalPrice);
        setTotalPrice(totalPrice.toFixed(2));
        setbarcodeList(listUpdated);
    }

    const updatePrice = (priceIn, barcodeInput, productID) => {
        console.log("Update price :" + priceIn + " for the barcode" + barcodeInput + " productID :" + productID);
        let foundIndex = barcodeList.findIndex(x => x.barcode === barcodeInput);
        //console.log(foundIndex);
        if (foundIndex !== -1) {// here we do update  
            let list = barcodeList;
            list[foundIndex].price = priceIn;
            if (list[foundIndex].productID === 0) {
                list[foundIndex].productID = productID;
            }
            setbarcodeList(list);
            console.log(barcodeList);
            updateTotalPrice();
        }
    }

    const updateTotalPrice = () => {
        console.log("Update total price");
        //  setbarcodeList(barcodeList);
        let totalPrice = 0;
        barcodeList.forEach(element => totalPrice += (element.price * element.count));
      //  console.log(barcodeList);
        console.log(totalPrice);
        setTotalPrice(totalPrice.toFixed(2));
    }

    const changeQuantity = (quantityTOCHANGE, barcodeINDEX) => {
        console.log("Changing Quantity from Child =>" + quantityTOCHANGE + " =>" + barcodeINDEX);
        let foundIndex = barcodeList.findIndex(x => x.barcode === barcodeINDEX);
        //  console.log(foundIndex);
        if (foundIndex !== -1) {// here we do update  
            let list = barcodeList;
            list[foundIndex].count = parseInt(quantityTOCHANGE);
            setbarcodeList(list);
            console.log(barcodeList);
            updateTotalPrice();
        }
    }


    const cancelSellOther = () => {
        console.log("Cancelling");
        let newList = [];
        setbarcodeList(newList);
        setTotalPrice(0);
    }

    const acceptsPayment = () => {
        console.log(`accept payment ${props.domain} | | ${props.barcode}`);
        setAcceptsPayment(true);
        setError("");
        barcodeList.forEach(function (e) {
            console.log(e);

            //We need to make sure its ok
            axios.get(`https://${props.domain}/wp-json/wc/v3/products?page=1&per_page=100&sku=${e.barcode}&consumer_key=${props.keyValue}&consumer_secret=${props.secret}`)
                .then(res => {
                    console.log("Accept Paid inside");
                    console.log(res);
                    if (res.data.length !== 0) {

                        console.log("Response from quality check")
                        console.log(res.data);

                        if (res.data[0].stock_quantity < parseInt(e.count)) {
                            console.log("Error Check inside");
                            setAcceptsPayment(false);
                            setError(`Product Quantity ${res.data[0].name} :Stock Quantity is ${res.data[0].stock_quantity}, selling quantity is :${e.count}`);
                            props.addLogs("ERROR", `Product Quantity ${res.data[0].name} :Stock Quantity is ${res.data[0].stock_quantity}, selling quantity is :${e.count}`, "acceptsPayment -catch", JSON.stringify(barcodeList), 0, "Inventory System Wordpress", "sell.js", props.domain);

                            return;
                        }

                        let productToUpdate = {
                            "manage_stock": true,
                            "stock_quantity": parseInt(res.data[0].stock_quantity) - parseInt(e.count),
                        };
                        console.log("Products to update")

                        console.log(productToUpdate);
                        axios.put(`https://${props.domain}/wp-json/wc/v3/products/${res.data[0].id}?consumer_key=${props.keyValue}&consumer_secret=${props.secret}`, productToUpdate)
                            .then(res1 => {
                                console.log(res1.data);
                                console.log("response after update");
                                setAcceptsPayment(false);
                                //Remove item from the list
                                props.addLogs("INFO", `Product data before :${res.data[0].stock_quantity} and to update : ${JSON.stringify(productToUpdate)}`, "product id", `${res.data[0].id}`, 0, "Inventory System Wordpress", "sell.js", props.domain);
                                setDeleted(e.index);
                                removeObjectFromListAtPosition(e.index);
                               
                            })
                            .catch(err => {
                                console.log(err);
                                setError(errorShow + "\n" + err);
                                props.addLogs("ERROR", err, "acceptsPayment -catch", JSON.stringify(barcodeList), 0, "Inventory System Wordpress", "sell.js", props.domain);

                                setAcceptsPayment(false);
                            })

                    }
                }).catch(error => {
                    console.log(error);
                    props.addLogs("ERROR", error, "acceptsPayment -catch", JSON.stringify(barcodeList), 0, "Inventory System Wordpress", "sell.js", props.domain);
                    setError(errorShow + "\n" + error);
                    setAcceptsPayment(false);
                });
            //then we update the quantity
        });

    }

    let showError = "";

    if (errorShow.length > 0) {
        showError = (<Alert variant="danger" dismissible>
            <Alert.Heading>Error</Alert.Heading>
            <p style={{ fontSize: '20px' }}> {errorShow} </p>
        </Alert>);
    }


    return (
        <div style={{ marginTop: '11%' }}>
            <p className="mb-0" style={{ paddingBottom: '5%' }}>
                Total Price: <strong>€{totalPrice}</strong>
            </p>
            <Form.Control size="lg" type="text" placeholder="Barcode" autoFocus onKeyPress={e => enterClicked(e)} onChange={e => setBarcodeInput(e.target.value)} value={barcodeInput} />
            <br />
            <Table responsive variant="light">
                <thead>
                    <tr style={{ fontSize: 'large', textAlign: 'left' }}>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Time</th>
                        <th>Barcode</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {barcodeList !== 0 ? barcodeList.map((barcodeItem) =>  <ProductListItem setDeleted={setDeleted} key={barcodeItem.index} deleted={barcodeItem.deleted} priceUpdate={updatePrice} quantity={barcodeItem.count} id={barcodeItem.index} barcode={barcodeItem.barcode} removeAtPosition={removeObjectFromListAtPosition} changeQuantityValue={changeQuantity} />) : ""}
                </tbody>
            </Table>
            <Alert variant="light" style={{ height: '100%' }}>
                <Button onClick={acceptsPayment} style={{ float: 'left', width: '45%' }} variant="success">{acceptsPayment_button ? <Spinner style={{ height: '20px', width: '20px' }} animation="grow" variant="light" /> : "Accept Paid"} </Button>
                <Button onClick={cancelSellOther} style={{ float: 'right', width: '45%' }} variant="danger"> Cancel</Button>
                <hr />
            </Alert>
            {showError}
        </div>
    );
}

const mapStateToProps = state => {
    // console.log(state);
    return {
        keyValue: state.auth.key,
        domain: state.auth.domain,
        secret: state.auth.secret
    }
};

const mapDispatchToProps = dispatch => {

    return {
        addLogs: (type, error_message, variableName, value, line, project, class_, user) => dispatch(actions.setLog(type, error_message, variableName, value, line, project, class_, user))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Sell);