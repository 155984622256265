import React, { useState } from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import * as actions from '../../store/action/index';

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
// import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'

import ToastCompile from '../UI/Toast/Toast';
import classes from './Product.module.css';
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup'

import { FaPlusCircle,FaMinusCircle,FaBarcode,FaBoxes,FaEuroSign } from "react-icons/fa";

const Product = (props) => {

    const [stockStatus, setStockStatus] = useState(props.data.stock_status);
    const [stockQuantity, setstockQuantity] = useState(props.data.stock_quantity);
    const [isText, setText] = useState(false);
    const [barcode, setBarcode] = useState(props.data.sku.length === 0 ? "" : props.data.sku);
    const [loading, setLoading] = useState(props.loading);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleFocus = (event) => event.target.select();

    const addQuantity = (quantity) => {
        setLoading(true);
        let productToUpdate = {
            "manage_stock": true,
            "stock_quantity": stockQuantity + quantity,
        };
        axios.put(`https://${props.domain}/wp-json/wc/v3/products/${props.data.id}?consumer_key=${props.keyValue}&consumer_secret=${props.secret}`, productToUpdate)
            .then(res => {
                setStockStatus(res.data.stock_status);//stock_quantity
                setstockQuantity(res.data.stock_quantity);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err);
                setError(true);
                setLoading(false);
            })
    }



    const buttonClicked = () => {
        setText(true);
    }

    const enterClickedOnBarcode = (e) => {
        let value = e.target.value;
        if (e.key === 'Enter') {
            if (value.toString() === props.data.sku.toString()) {
                console.log("###### " + value);
                setText(false);
                return;
            }
            setLoading(true);
            let productToUpdate = {
                "sku": value
            };
            axios.put(`https://${props.domain}/wp-json/wc/v3/products/${props.data.id}?consumer_key=${props.keyValue}&consumer_secret=${props.secret}`, productToUpdate)
                .then(res => {
                    setBarcode(res.data.sku);//set sku 
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    setErrorMessage(err);
                    setError(true);
                    setLoading(false);
                })
            setText(false);
        }
    }

    let spinner = (<Spinner animation="border" variant="primary" />);
    let barcode_label = (<Button variant="outline-dark" onClick={() => buttonClicked()}>{barcode.length === 0 ? 'Add barcode' : barcode}</Button>)
    if (isText) {
        barcode_label = (<input type="text" style={{ width: '70%' }} onFocus={handleFocus} onKeyPress={e => enterClickedOnBarcode(e)} onChange={e => setBarcode(e.target.value)} value={barcode}></input>);
    }
    if (loading) {
        barcode_label = spinner;
    }


    let product = (<h3>Laduje...</h3>);

    let image = "";
    if (props.data.images.length === 0) {
        image = process.env.PUBLIC_URL + "/no_Image.png";
    } else {
        image = props.data.images[0].src;
    }
    if (props.data != null) {
        product = (
            <div> {error ? <ToastCompile message={errorMessage} setError={setError} /> : ""}
                <Card style={{ width: '18rem', color: '#534d4d', height: '420px' }} >
                    <Badge style={{ position: 'absolute', top: '15px', left: '5px', fontSize: '13px' }} variant={(stockQuantity === 0 ? 'danger' : 'success')}> {stockStatus} </Badge>
                    <Card.Img style={{ height: '100px', width: 'auto', marginTop: '9px' }} variant="top" src={image} />
                    <Card.Body>
                        <Card.Title style={{ height: '50px', fontSize: 'medium' }}>{props.data.name}</Card.Title>
                        <div className={classes.infoImage}>
                            <ListGroup variant="flush" style={{fontSize: '15px'}}>
                                <ListGroup.Item><FaBarcode style={{ fontSize: '2.4em', float: 'left'}} />{barcode_label}</ListGroup.Item>
                                <ListGroup.Item style={{ fontSize: '1.2em' }}><FaEuroSign style={{ fontSize: '1.4em' }}/>{props.data.price}</ListGroup.Item>
                                <ListGroup.Item><FaBoxes style={{ fontSize: '1.9em', float: 'left'}}/>{loading ? spinner : stockQuantity}</ListGroup.Item>
                                <ListGroup.Item >
                                <Button variant="success" style={{width:'45%',height:'50px',position:'absolute',top:'0',left:'0'}} disabled={loading} onClick={() => addQuantity(1)} size="lg" ><FaPlusCircle/></Button>
                                <Button variant="danger" style={{width:'45%',height:'50px',position:'absolute',top:'0',right:'0'}} disabled={loading || (stockQuantity <= 0)} onClick={() => addQuantity(-1)} size="lg" ><FaMinusCircle/></Button>
                                </ListGroup.Item>
                            </ListGroup>
                            
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    return (
        <div>
            {product}
        </div>
    )
}

// setStockStatus(res.data.stock_status);//stock_quantity
// setstockQuantity(res.data.stock_quantity);


const mapStateToProps = state => {
    // console.log(state);
    return {
        product: state.updateProduct.product,
        loading: state.updateProduct.loading,
        ID: state.updateProduct.id,
        error: state.updateProduct.error,
        keyValue: state.auth.key,
        domain: state.auth.domain,
        secret: state.auth.secret
    }
};

const mapDispatchToProps = dispatch => {

    return {
        onUpdateProduct: (key, secret, domain, productId, productUpdate) => dispatch(actions.updateProducts(key, secret, domain, productId, productUpdate))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Product);
