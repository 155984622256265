

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/action/index';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import classes from './login.module.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge'

const Login = (props) => {

    const [domain, setDomain] = useState("");
    const [key, setKey] = useState("");
    const [secret, setSecret] = useState("");

    const submitHandler = (event) => {
        event.preventDefault();
        props.onAuth(key, secret, domain);
    }

    useEffect(() => {
        if (!props.idAuth) {
            props.onTryAuthSignup();
        }
    }, []);

    const loading = (<Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
    </Spinner>);

    return (
        <div>

            <Container className={classes.card_container} style={{ width: "450px", padding: "25px" }}>
                <Card className={classes.card, classes.login}>
                    <img id={classes.profile_img} className={classes.profile_img_card} src={process.env.PUBLIC_URL + "/beGoodLogo.png"} />
                    <p id={classes.profile_name} className={classes.profile_name_card}>Inventory Manager</p>
                    <Form className={classes.form_signin} style={{ padding: '15px' }} onSubmit={submitHandler}>
                        <Form.Group controlId="formBasicDomain">
                            <Form.Control type="text" onChange={e => setDomain(e.target.value)} value={domain} placeholder="DOMAIN  (example.ie)" />
                        </Form.Group>
                        <Form.Group controlId="formBasicKEY">
                            <Form.Control type="text" onChange={e => setKey(e.target.value)} value={key} placeholder="API KEY" />
                        </Form.Group>
                        <Form.Group controlId="formBasicSecret">
                            <Form.Control type="password" onChange={e => setSecret(e.target.value)} value={secret} placeholder="API SECRET" />
                        </Form.Group>
                        <Button variant="primary" size="lg" block className={classes.btn_signin, classes.btn} type="submit">{props.loading !== true ? 'Sign in' : loading}</Button>
                        {/* <br/> */}
                        <Form.Text className="text-muted"> Powered by  <a href="https://softwarestudio.ie"><Badge variant="light"> Software Studio</Badge></a> Software</Form.Text>
                        {/* <Form.Text className="text-muted">Copyright © 2021 <b>BeGood</b> <br/><br/> Powered by  <a href="https://softwarestudio.ie"><Badge variant="light"> Software Studio</Badge></a> Software</Form.Text> */}
                    </Form>
                </Card>
            </Container>
        </div>
    );



}

const mapStateToProps = state => {
    // console.log(state);
    return {
        isAuth: state.auth.key !== null,
        loading: state.auth.loading,
        error: state.auth.error
    }
};

const mapDispatchToProps = dispatch => {

    return {
        onAuth: (key, secret, domain) => dispatch(actions.auth(key, secret, domain)),
        onTryAuthSignup: () => dispatch(actions.authCheckState())
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Login);

