import * as actionTypes from '../action/actionTypes';
import { UpdateObject } from '../Utilities';


const initialState = { 
    error: null,
    Logs:null,
};


const logStart = (state, action) => {
  //  console.log(action);
    return UpdateObject(state, { error: null, Logs:null });
}

const logSuccess = (state, action) => {
   // console.log(action);
    return UpdateObject(state, {
        Logs: action.log, 
        error: null 
    });
}

 
const logFaile = (state, action) => {
  //  console.log(action);
    return UpdateObject(state, {
        error: action.error
    });
}

const reducer = (state= initialState, action) => {
  //  console.log(action);
    switch (action.type) {
        case actionTypes.LOGS_START: return logStart(state, action);
        case actionTypes.LOGS_SUCCESSION: return logSuccess(state, action);
        case actionTypes.LOGS_FAIL: return logFaile(state, action); 
        default: return state;
    }
}


export default reducer;