import * as actionTypes from '../action/actionTypes';
import { UpdateObject } from '../Utilities';


const initialState = {
    key: null,
    secret: null,
    error: null,
    loading: false,
    domain:null,
    authRedirectPath:'/'
};


const authStart = (state, action) => {
    return UpdateObject(state, { error: null, loading: true });
}

const authSuccess = (state, action) => {
    return UpdateObject(state, {
        key: action.key,
        secret: action.secret,
        domain:action.domain,
        error: null,
        loading: false
    });
}

const authLogout =(state,action)=>{
    return UpdateObject(state,{key:null,secret:null});
};

const authFail = (state, action) => {
    return UpdateObject(state, {
        error: action.error,
        loading: false
    });
}

const setAuthRedirectPath =(state,action) =>{
    return UpdateObject(state,{authRedirectPath:action.path})
}

const reducer = (state= initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:return authLogout(state,action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default: return state;
    }
}


export default reducer;