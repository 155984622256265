import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../../../store/action/index';


// import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { getCurrentTime } from '../../../store/Utilities';
import { FaCheck,FaDizzy } from "react-icons/fa";

const ProductListItem = (props) => {
    let spinner = (<Spinner animation="border" variant="primary" />);

    const [product, updateProduct] = useState([]);
    const [dateValue, setDate] = useState(getCurrentTime());
    const [quantity, setQuantity] = useState(props.quantity);
    const [toDelete, setToDelete] = useState(false);

    useEffect(() => {

        if(props.deleted){
            return;
        }
        const fetchData = async () => {
             await axios.get(`https://${props.domain}/wp-json/wc/v3/products?page=1&per_page=100&sku=${props.barcode}&consumer_key=${props.keyValue}&consumer_secret=${props.secret}`)
                .then(res => {
                    if (res.data.length !== 0) {
                        updateProduct(res.data);
                        if (props.priceUpdate != null) {
                            props.priceUpdate(res.data[0].regular_price, props.barcode,res.data[0].id);
                        }
                        //     console.log(res.data);
                    } else {
                        DeleteAtCurentPosition();
                        props.addLogs("INFO","Product at specific barcode not found ->"+props.barcode,"fetchData-logic error",JSON.stringify(props.barcode),0,"Inventory System Wordpress","ProductListItem.js",props.domain);
                        setToDelete(true);
                        props.setDeleted(props.id);
                        //props.setError("Product not found ->"+props.barcode);
                    }
                }).catch(error => {
                    props.addLogs("ERROR",error,"Product List Item -catch",JSON.stringify(props),0,"Inventory System Wordpress","ProductListItem.js",props.domain);
                    // console.log(error);
                   // props.setError("Send This Error to Support :"+error);
                });
        };
        if (product.length === 0 && toDelete===false) {
            fetchData(); 
            setDate(getCurrentTime());
        }
        if (props.quantity !== quantity) {
            setQuantity(props.quantity);
        }

    }, [props.quantity]);


    const AcceptData = () => {
        addQuantity(quantity);//
        //Removing position from List

        console.log("Added Button Clicked");
    }
    const DeleteAtCurentPosition = () => {
        //Removing position from List
        console.log("Removed Button Clicked");
        props.removeAtPosition(props.id);
        setToDelete(true);
    }

    const changeQuantity = (quantity) => {
        console.log("value =>" + quantity)
        props.changeQuantityValue(quantity, props.barcode);
        setQuantity(quantity);
    }


    const addQuantity = (quantity) => {
        //  setLoading(true);
        console.log(product);
        let productTemp = product;
        updateProduct([]);
        let productToUpdate = {
            "manage_stock": true,
            "stock_quantity": parseInt(productTemp[0].stock_quantity) + parseInt(quantity),
        };
        console.log(productToUpdate);
        axios.put(`https://${props.domain}/wp-json/wc/v3/products/${productTemp[0].id}?consumer_key=${props.keyValue}&consumer_secret=${props.secret}`, productToUpdate)
            .then(res => {
                console.log(res.data);
                props.removeAtPosition(props.id);
                //     setStockStatus(res.data.stock_status);//stock_quantity
                //     setstockQuantity(res.data.stock_quantity);
                //     setLoading(false);
            })
            .catch(err => {
                console.log(err);
                props.addLogs("ERROR",err,"addQuantity-catch",JSON.stringify(props),100,"Inventory System Wordpress","ProductListItem.js",props.domain);

                updateProduct(productTemp);
              //  props.setError("Send This Error to Support :"+err);
                //    setErrorMessage(err);
                //    setError(true);
                //    setLoading(false); 
            })
    }



    let image = "";
    if (product.length) {
        console.log(product[0].images);
        if (product[0].images.length === 0) {
            image = process.env.PUBLIC_URL + "/no_Image.png";
           // props.setError("Product dose not have image");

        } else {
            image = product[0].images[0].src;
        }
    }
    let quantityCSS =2;
    if(product.length > 0){
        quantityCSS=parseInt(product[0].stock_quantity);
    }

    return (
         
            <tr style={{ fontSize: 'medium',textAlign: 'left',backgroundColor:(quantityCSS<=parseInt(quantity)) ?'red' :'#0000'}}>
                <td>{props.id}</td>
                <td>{product.length > 0 ? <Image src={image} thumbnail width={35} /> : spinner}</td>
                <td>{product.length > 0 ? dateValue : spinner}</td>
                <td>{product.length > 0 ? product[0].sku : spinner}</td>
                <td>{product.length > 0 ? product[0].name : spinner}</td>
                <td>{product.length > 0 ? product[0].regular_price : spinner}</td>
                <td style={{ width: '50px' }}>{product.length > 0 ? <Form.Control size="sm" value={quantity} type="number" onChange={e => changeQuantity(e.target.value)} /> : spinner}</td>
               {/* {(props.priceUpdate == null) ?  <td>{product.length > 0 ? <Button onClick={() => AcceptData()} variant="success"><FaCheck/> </Button> : spinner : ''}</td> */}
               {props.priceUpdate == null ? <td>{product.length > 0 ? <Button onClick={() => AcceptData()} variant="success"><FaCheck/> </Button> : spinner}</td> : ''}
                <td>{product.length > 0 ? <Button onClick={() => DeleteAtCurentPosition()} variant="danger"> <FaDizzy/></Button> : spinner}</td>
            </tr>  
    );


}

const mapStateToProps = state => {
    //  console.log(state);
    return {
        error: state.updateProduct.error,
        keyValue: state.auth.key,
        domain: state.auth.domain,
        secret: state.auth.secret
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateProduct: (key, secret, domain, productId, productUpdate) => dispatch(actions.updateProducts(key, secret, domain, productId, productUpdate)),
        addLogs: (type, error_message, variableName, value, line, project, class_,user) => dispatch(actions.setLog(type, error_message, variableName, value, line, project, class_,user))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem);
