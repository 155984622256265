
import React from 'react';
import Toast from 'react-bootstrap/Toast'; 



const ToastCompile = (props) => {
    // const [showA, setShowA] = useState(true);
     const toggleShowA = () => props.setError(false);


     let toast = (
        <Toast onClose={toggleShowA}>
        <Toast.Header>
            <strong className="mr-auto">Error</strong>
            <small>{props.message}</small>
        </Toast.Header>
        <Toast.Body>There is error updating the product</Toast.Body>
    </Toast> 
    );

    if(props.message.length ===0){
        toast=(<span></span>);
    }

    return (
        {toast}
    );

}

export default ToastCompile;