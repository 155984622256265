import * as actionTypes from '../action/actionTypes';
import { UpdateObject } from '../Utilities';


const initialState = {
    page: 1,
    productNumber: 10,
    error: null,
    loading: false,
    products: [],
    search:"",
    barcode: "",
};


const getProductStart = (state, action) => {
    return UpdateObject(state, { error: null, loading: true });
}

const getProductSuccess = (state, action) => {
    
    return UpdateObject(state, {
        page: action.page,
        productNumber: action.productNumber,
        products:action.products,
        search:action.search,
        barcode:action.barcode,
        error: null,
        loading: false
    });
}

 

const getProductFail = (state, action) => {
    return UpdateObject(state, {
        error: action.error,
        loading: false
    });
}


const reducer = (state= initialState, action) => {
   // console.log(action);
    switch (action.type) {
        case actionTypes.FETCH_SHOP_ITEMS_START: return getProductStart(state, action);
        case actionTypes.FETCH_SHOP_ITEMS_SUCCESS: return getProductSuccess(state, action);
        case actionTypes.FETCH_SHOP_ITEMS_FAIL: return getProductFail(state, action);
        
        default: return state;
    }
}


export default reducer;