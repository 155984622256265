import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/action/index';

// import Container from 'react-bootstrap/Container'

// import classes from './loyout.module.css';
import Navigation from './../components/Navigation/navigation';
import SideNavigation from './../components/SideNavigation/sideNavigation';
import Footer from '../components/Footer/footer';


const Layout = (props) => {


    useEffect(() => {
        if (!props.idAuth) {
            props.onTryAuthSignup();
        }
    }, []);

    return (
        <div fluid={true}> 
        <Navigation idAuth={props.isAuth} logout={props.onLogout} />
        <SideNavigation idAuth={props.isAuth} logout={props.onLogout}/>
            <header className="App-header">  
                {props.children}
            </header>
            <Footer />
        </div>
    )
}

const mapStateToProps = state => {
    console.log(state);
    return {
        isAuth: state.auth.key !== null,
        loading: state.auth.loading,
        error: state.auth.error
    }
};

const mapDispatchToProps = dispatch => {

    return {
        onLogout: () => dispatch(actions.logout()),
        onTryAuthSignup: () => dispatch(actions.authCheckState())
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Layout);

