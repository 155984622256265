export const UpdateObject = (oldObject, updatedProperties) => {return {...oldObject,...updatedProperties};}



export function getCurrentDate(separator = '/') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let h = newDate.getUTCHours();
    let m = newDate.getUTCMinutes();
    let s = newDate.getUTCSeconds();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${h}${separator}${m}${separator}${s}`
}

export function getCurrentTime(separator = ':') {

    let newDate = new Date()
    let h = newDate.getUTCHours();
    let m = newDate.getUTCMinutes();
    let s = newDate.getUTCSeconds();
    // var n = newDate.getUTCMilliseconds();
    return `${h}${separator}${m}${separator}${s}`
}