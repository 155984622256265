import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getLogStart = () => {
    return {
        type: actionTypes.LOGS_START
    };
}

export const getLogSuccess = (log) => {
    // console.log(log)
    return {
        type: actionTypes.LOGS_SUCCESSION,
        log: log.data
    };
}

export const getLogFail = (error) => {
    return {
        type: actionTypes.LOGS_FAIL,
        error: error
    };
}

export const setLog = (type, error_message, variableName, value, line, project, class_, user) => {
    // console.log(type);
    return dispatch => {
        dispatch(getLogStart());
        const body = {
            "type": type,
            "error_message": error_message,
            "variableName": variableName,
            "value": value,
            "line": line,
            "project": project,
            "class": class_,
            "user": user,
            "token": "sha1$8e184047$1$90aa663f5b461158ef3938f17d77193a23fa2929",
            "userId": 3
        }
        //   console.log(body);
        let url = `https://1platypus.xyz:4001/e_log`;
        //console.log(url);
        axios.post(url, body)
            .then(response => {
                //  console.log(response);
                dispatch(getLogSuccess(response));
            })
            .catch(err => {
                console.log(err);
                //   console.log(url);
                dispatch(getLogFail(err));
            })
    }
}

