

export const FETCH_SHOP_ITEMS_INI ='FETCH_SHOP_ITEMS_INI';
export const FETCH_SHOP_ITEMS_START ='FETCH_SHOP_ITEMS_START';
export const FETCH_SHOP_ITEMS_FAIL ='FETCH_SHOP_ITEMS_FAIL';
export const FETCH_SHOP_ITEMS_SUCCESS ='FETCH_SHOP_ITEMS_SUCCESS';
export const FETCH_SHOP_ITEMS_CLEAR ='FETCH_SHOP_ITEMS_CLEAR';

export const FETCH_PRODUCT_BY_BARCODE_INIT ='FETCH_PRODUCT_BY_BARCODE_INIT';
export const FETCH_PRODUCT_BY_BARCODE_START ='FETCH_PRODUCT_BY_BARCODE_START';
export const FETCH_PRODUCT_BY_BARCODE_FAIL ='FETCH_PRODUCT_BY_BARCODE_FAIL';
export const FETCH_PRODUCT_BY_BARCODE_SUCCESS ='FETCH_PRODUCT_BY_BARCODE_SUCCESS';
export const FETCH_PRODUCT_BY_BARCODE_CLEAR ='FETCH_PRODUCT_BY_BARCODE_CLEAR';

export const UPDATE_PRODUCT_INI='UPDATE_PRODUCT_INI';
export const UPDATE_PRODUCT_START='UPDATE_PRODUCT_START';
export const UPDATE_PRODUCT_FAIL='UPDATE_PRODUCT_FAIL';
export const UPDATE_PRODUCT_SUCCESS='UPDATE_PRODUCT_SUCCESS';

export const AUTH_START='AUTH_START';
export const AUTH_SUCCESS ='AUTH_SUCCESS';
export const AUTH_FAIL ='AUTH_FAIL';
export const AUTH_LOGOUT='AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH='SET_AUTH_REDIRECT_PATH';

export const LOGS_START='LOGS_START';
export const LOGS_SUCCESSION ='LOGS_SUCCESSION';
export const LOGS_FAIL='LOGS_FAIL';