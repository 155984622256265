import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table'
import ProductListItem from '../../components/UI/ProductListItem/ProductListItem';
import { getCurrentTime } from '../../store/Utilities';

const AddStockets = (props) => {

    const [barcodeInput, setBarcodeInput] = useState("");
    const [barcodeList, setbarcodeList] = useState([]);
    const [indexBarcode, setIndexBarcodeList] = useState(0);

    const enterClicked = (e) => {

        if (e.key === 'Enter') {

            let foundIndex = barcodeList.findIndex(x => x.barcode === barcodeInput);
            //console.log(foundIndex);
            if (foundIndex !== -1) {// here we do update  
                let list = barcodeList;
                list[foundIndex].count = list[foundIndex].count + 1;
                setbarcodeList(list);
            } else { // here we just add 
                console.log("don't exist");
                setbarcodeList(barcodeList => [...barcodeList, { currentTime: getCurrentTime(), barcode: barcodeInput, index: indexBarcode, count: 1 }]);
            }
            setIndexBarcodeList(indexBarcode + 1);
            setBarcodeInput("");
        }

    }

    const removeObjectFromListAtPosition = (position) => {
        console.log("Remove from position in List parent" + position);
        let list = barcodeList;
        const removeItem = (items, i) => items.filter(item => item.index !== position);
        setbarcodeList(removeItem(list, position));
    }

    const changeQuantity = (quantityTOCHANGE, barcodeINDEX) => {
        console.log("Changing Quantity from Child =>" + quantityTOCHANGE + " =>" + barcodeINDEX);
        let foundIndex = barcodeList.findIndex(x => x.barcode === barcodeINDEX);
        //  console.log(foundIndex);
        if (foundIndex !== -1) {// here we do update  
            let list = barcodeList;
            list[foundIndex].count = parseInt(quantityTOCHANGE);
            setbarcodeList(list);
            console.log(barcodeList);
        }
    }

    return (
        <div>
          
              <br/>
              <br/>
              <p>Adding Products to the stock</p>
            <Form.Control size="lg" type="text" placeholder="Barcode" autoFocus onKeyPress={e => enterClicked(e)} onChange={e => setBarcodeInput(e.target.value)} value={barcodeInput} />
            <br/>
            <Table responsive variant="light">
                <thead>
                    <tr style={{ fontSize: 'large',textAlign: 'left'}}>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Time</th>
                        <th>Barcode</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Accept</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {barcodeList !== 0 ? barcodeList.map((barcodeItem) => <ProductListItem key={barcodeItem.index} quantity={barcodeItem.count} id={barcodeItem.index} barcode={barcodeItem.barcode} removeAtPosition={removeObjectFromListAtPosition} changeQuantityValue={changeQuantity} />) : ""}
                </tbody>
            </Table>
        </div>
    );

}

export default AddStockets;