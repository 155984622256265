import * as actionTypes from '../action/actionTypes';
import { UpdateObject } from '../Utilities';


const initialState = {
    error: null,
    loading: false,
    id:0,
    barcode: "",
    product:null
};

const updateProductStart = (state, action) => {
    return UpdateObject(state, { error: null, loading: true });
}


const updateProductSuccess = (state, action) => {
    return UpdateObject(state, {
        product:action.product,
        id:action.id,
        error: null,
        loading: false
    });
}

const updateProductFail = (state, action) => {
    return UpdateObject(state, {
        error: action.error,
        loading: false
    });
}


const reducer = (state= initialState, action) => {
   // console.log(action);
    switch (action.type) {
        case actionTypes.UPDATE_PRODUCT_START: return updateProductStart(state, action);
        case actionTypes.UPDATE_PRODUCT_SUCCESS: return updateProductSuccess(state, action);
        case actionTypes.UPDATE_PRODUCT_FAIL: return updateProductFail(state, action);
        default: return state;
    }
}


export default reducer;