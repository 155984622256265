import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { NavLink } from 'react-router-dom';

import { FaCashRegister, FaCaretSquareUp, FaBarcode, FaBoxes, FaClinicMedical, FaPowerOff } from "react-icons/fa";

const SideNavigation = (props) => {
    return (
        <SideNav style={{ backgroundColor: 'black', height: '100%', top: '55px' }} onSelect={(selected) => {
            // Add your code here
            console.log(selected);
        }} >
            <SideNav.Toggle />
            <SideNav.Nav defaultSelected="home">
                <NavItem eventKey="home">
                    <NavIcon>
                        <NavLink to="/">
                            <FaClinicMedical style={{ fontSize: '1.75em' }} />
                        </NavLink>
                    </NavIcon>
                    <NavText> <NavLink to="/">  Home </NavLink></NavText>
                </NavItem>
                <NavItem eventKey="Shop">
                    <NavIcon>
                        <NavLink to="/shop">
                            <FaBoxes style={{ fontSize: '1.75em' }} />
                        </NavLink>
                    </NavIcon>
                    <NavText> <NavLink to="/addProducts"> Shop</NavLink> </NavText>
                </NavItem>
                <NavItem eventKey="Add Products">
                    <NavIcon>
                        <NavLink to="/addProducts">
                            <FaCaretSquareUp style={{ fontSize: '1.75em' }} />
                        </NavLink>
                    </NavIcon>
                    <NavText> <NavLink to="/addProducts"> Add Products </NavLink></NavText>
                </NavItem>
                <NavItem eventKey="Sell">
                    <NavIcon>
                        <NavLink to="/sell">
                            <FaCashRegister style={{ fontSize: '1.75em' }} />
                        </NavLink>
                    </NavIcon>
                    <NavText><NavLink to="/sell">Sell</NavLink> </NavText>
                </NavItem>
                {/* <NavItem eventKey="Barcode">
                    <NavIcon>
                        <NavLink to="/addProducts">
                            <FaBarcode style={{ fontSize: '1.75em' }} />
                        </NavLink>
                    </NavIcon>
                    <NavText> <NavLink to="/addProducts"> Barcode Generator</NavLink> </NavText>
                </NavItem> */}
                <NavItem eventKey="logOut" onClick={props.logout} style={{marginTop:'250px'}}>
                    <NavIcon> 
                        <FaPowerOff  style={{ fontSize: '1.75em',color:'yellow' }} /> 
                    </NavIcon>
                    <NavText> Logout </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav >
    )

}



export default SideNavigation;

