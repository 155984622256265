

import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/action/index';
import Alert from 'react-bootstrap/Alert';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button' 

const LogedIn = (props) => {

    
    return (
        <div style={{marginLeft:'69px',marginRight:'15px'}}>
            <br/>
            <h1>Logged in</h1>
            <Alert variant="success">
                <Alert.Heading>Hello here is a new loggedIn page <br/> in the future I am planning to add interesting features to it.</Alert.Heading>
                <hr />
                <p style={{fontSize:'15px'}}> info@softwarestudio.ie </p>
            </Alert>
        </div>
    );



}

const mapStateToProps = state => {
    // console.log(state);
    return {
        isAuth: state.auth.key !== null,
        loading: state.auth.loading,
        error: state.auth.error
    }
};

const mapDispatchToProps = dispatch => {

    return {
        onAuth: (key, secret, domain) => dispatch(actions.auth(key, secret, domain))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(LogedIn);

