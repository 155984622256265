
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/action/index';

import CardColumns from 'react-bootstrap/CardColumns'
// import CardDeck from 'react-bootstrap/CardDeck'
// import CardGroup from 'react-bootstrap/CardGroup'
import Spinner from 'react-bootstrap/Spinner';
import Product from '../../components/ProductCard/ProductCard';
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const Shop = (props) => {

    const [page, setPage] = useState(1);
    const [quantity, setQuantity] = useState(10);
    const [search, setSearch] = useState("");
    const [barcode, setBarcode] = useState("");

    useEffect(() => {
        // console.log(props);
        if (props.products.length === 0 && props.error === null & props.isAuth) {
            props.getProducts(props.keyValue, props.secret, props.domain, props.page, props.per_page, props.search, props.barcode);
        }
        setPage(props.page);
        setQuantity(props.per_page);
        setSearch(props.search);
        setBarcode(props.barcode);
    }, []);

    const searchChange = (e) => {
        let value = e.target.attributes.id.nodeValue;
        //  console.log(value);
        switch (value) {
            case 'page':
                setPage(e.target.value);
                props.getProducts(props.keyValue, props.secret, props.domain, e.target.value, quantity, search, barcode);
                break;
            case 'quantity':
                setQuantity(parseInt(e.target.value));
                props.getProducts(props.keyValue, props.secret, props.domain, page, e.target.value, search, barcode);
                break;
            case 'barcode':
                if (e.key === 'Enter') {
                    setPage(1);
                    setQuantity(10);
                    props.getProducts(props.keyValue, props.secret, props.domain, 1, 10, search, barcode);
                }
                break;
            case 'search':
                if (e.key === 'Enter') {
                    setPage(1);
                    setQuantity(10);
                    props.getProducts(props.keyValue, props.secret, props.domain, 1, 10, search, barcode);
                }
                break;
            default:
                break;
        }
    }

    const clearBarcode = () => {
        setBarcode('');
        props.getProducts(props.keyValue, props.secret, props.domain, page, quantity, search, '');

    }
    const clearSearch = () => {
        setSearch('');
        props.getProducts(props.keyValue, props.secret, props.domain, page, quantity, '', barcode);
    }


    // let spinner = (<Spinner animation="border" variant="primary" />);
    return (
        <div>
            <Navbar bg="primary" variant="dark">
                <Form inline style={{ width: '100%', display: 'unset', float: 'right' }}>
                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        value={quantity}
                        id='quantity'
                        onChange={e => searchChange(e)}
                        custom>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                    </Form.Control>

                    <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        value={page}
                        id='page'
                        onChange={e => searchChange(e)}
                        custom>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                    </Form.Control>
                    <Form.Control style={{ width: '30%', 'backgroundColor': 'black', marginLeft: '2%', color: 'white', weight: 'bold' }}
                        type="text" id='barcode' placeholder="Search Barcode" onKeyPress={e => searchChange(e)} onChange={e => setBarcode(e.target.value)} value={barcode} className="mr-sm-2" />
                    <Button variant="dark" onClick={clearBarcode} >X</Button>
                    <Form.Control style={{ width: '25%', 'backgroundColor': 'white', marginLeft: '2%' }}
                        type="text" id='search' placeholder="Search Product Name" onKeyPress={e => searchChange(e)} value={search} onChange={e => setSearch(e.target.value)} className="mr-sm-2" />
                    <Button variant="dark" onClick={clearSearch}>X</Button>
                </Form>
            </Navbar>
            <CardColumns>
                {props.products.length !== 0 ? props.products.map(product => <Product key={product.id} data={product} />) : ""}
            </CardColumns>
            <div>{props.products.loading !== true ? "" : <h1><Spinner animation="border" variant="primary" value={barcode} style={{ position: 'absolute', top: '50%', left: '50%' }} /></h1>} </div>
        </div>
    );


}



const mapStateToProps = state => {
    console.log(state);
    return {
        isAuth: state.auth.key !== null,
        keyValue: state.auth.key,
        domain: state.auth.domain,
        secret: state.auth.secret,
        loading: state.products.loading,
        error: state.products.error,
        page: state.products.page,
        per_page: state.products.productNumber,
        search: state.products.search,
        barcode: state.products.barcode,
        products: state.products.products
    }
};

const mapDispatchToProps = dispatch => {

    return {
        getProducts: (key, secret, domain, page, per_page, search, barcode) => dispatch(actions.getProducts(key, secret, domain, page, per_page, search, barcode))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Shop);
