

import classes from './footer.module.css';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge'


const Footer = (props) => {

    return (
        <div className={classes.mainComponent}> 
            <Form.Text className={classes.pFoters}>Copyright © 2021 <b>BeGood</b>  Powered by  <a href="https://softwarestudio.ie"><Badge variant="light"> Software Studio</Badge></a> Software</Form.Text>
        </div>
    );

}

export default Footer;